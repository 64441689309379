<template>
    <div class="main-container">
      <h5 class="pageTitle">드론 안정성 인증검사 등록</h5>
  
      <div class="form-container">
        <div class="form-group">
          <label for="model_ID">모델(타입) 선택</label>
          <select v-model="form.model_ID" class="select-box">
            <option v-for="model in droneModels" :key="model.uuid" :value="model.uuid">
              {{ model.model }}
            </option>
          </select>
        </div>
  
        <div class="form-group">
          <label for="name">안정성 인증 검사명</label>
          <input v-model="form.name" type="text" class="input-box" placeholder="인증 검사명을 입력하세요" />
        </div>
  
        <div class="form-group">
          <label for="expire_date">안정성 인증 검사 만료일</label>
          <input v-model="form.expire_date" type="date" class="input-box wide-input" />
        </div>
  
        <div class="form-group">
          <label for="smsYN">SMS 예약 여부</label>
          <select id="smsYN" v-model="form.sms_YN" class="select-box">
            <option :value="true">예</option>
            <option :value="false">아니오</option>
          </select>
        </div>
  
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-register" @click="registerSafetyCert">등록</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  import DailyCheckModule from "@/lib/DailyCheckModule";
  
  export default {
    name: "SafetyCertCreate",
    props: {
      certs: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        form: {
          model_ID: "", // UUID 값
          name: "",
          expire_date: "",
          sms_YN: true,
        },
        droneModels: [], // 드론 모델 리스트
      };
    },
    computed:{
      senderNumber() {
        return this.$store.state.senderNumber; // Vuex state에서 발신 번호 가져오기
      },
    },
    created() {
      this.fetchDroneModels(); // 드론 모델 데이터를 가져옴
      console.log("Received certs data:", this.certs);
    },
    methods: {
      async fetchDroneModels() {
        try {         
          const token = this.getToken();
  
          const response = await axios.get("/drones/type", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          this.droneModels = response.data;
        } catch (error) {
          Swal.fire("오류", "드론 모델 데이터를 불러오지 못했습니다.", "error");
          console.error("드론 모델 조회 오류:", error);
        }
      },
      async registerSafetyCert() {
        if (!this.form.model_ID || !this.form.name || !this.form.expire_date) {
          Swal.fire("오류", "모든 필드를 채워주세요.", "error");
          return;
        }
        //선택된 모델의 데이터들을 가져온다.
        const selectedCerts = this.certs.find(
          (model) => model.model_ID === this.form.model_ID
        );
        console.log("selectedCerts:", JSON.stringify(selectedCerts, null, 2));
  
        if(selectedCerts){
            // 1. 만료일 찾기 (selectedIns 자체에서 처리)
            const latestExpireDate = new Date(
                Math.max(
                  ...this.certs
                    .filter((cert) => cert.model_ID === this.form.model_ID)
                    .map((cert) => new Date(cert.expire_date).getTime())
                )
            );

            // 입력된 만료일
            const newExpireDate = new Date(`${this.form.expire_date}T23:59:59`);
            console.log("newExpireDate:", newExpireDate);

            // 2. 만료일 이전의 데이터 입력 확인 시 알림 메시지 출력
            if (newExpireDate <= latestExpireDate) {
              Swal.fire(
                "오류",
                "만료일이 기존 등록된 데이터보다 과거이거나 같을 수 없습니다.",
                "error"
              );
              return;
            }

            // 3. 알림 메시지 표시
            const result = await Swal.fire({
              title: "알림",
              text: "동일모델의 신규 만료일 등록시 기존 데이터의 SMS 예약 여부는 '아니오'로 자동 변경됩니다.",
              icon: "info",
              confirmButtonText: "확인",
            });

            if (result.isConfirmed) {
              await this.regCertBackend();
            }
        
        }else{
          await this.regCertBackend();
        }
      },

      async regCertBackend(){

        const token = this.getToken();
        const expireDateTime = new Date(`${this.form.expire_date}T23:59:59`);
        const formData = {
          ...this.form,
          expire_date: expireDateTime,
        };
        try {
          const response = await axios.post(
            "/safety-cert/create",
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
  
          if (response.status === 201) {
            Swal.fire("성공", "안정성 인증 검사가 성공적으로 등록되었습니다.", "success");

            //SMS 재생성
            await DailyCheckModule.recreateSmsData(this.senderNumber);
            
            this.resetForm();
            this.goToList();
          }
        } catch (error) {
          Swal.fire("오류", "등록 중 문제가 발생했습니다.", "error");
          console.error("등록 실패:", error);
        }
      },
        
    

      // 공통 메서드: 토큰 가져오기
      getToken() {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        return tokenData ? tokenData.access_token : "";
      },
      resetForm() {
        this.form = {
          model_ID: "",
          name: "",
          expire_date: "",
          sms_YN: true,
        };
      },
      goToList() {
        this.$router.push("/safetyCert-read");
      },
    },
  };
  </script>
  
  <style scoped>
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .input-box, .select-box {
    flex: 1;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
  }
  
  .button-container {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-register {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  </style>
  